import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {errorHandler} from '@/assets/scripts/scripts'
import dayjs from 'dayjs'

class EventsService {
  async getEvents(isLoadNew = false) {
    if (!isLoadNew) store.commit('other/onChangeLoadingItems', true)

    const {paginationPage, orderBy, sortedBy, filter, isArchive, elements, search} = store.state.events

    const params = {
      page: paginationPage,
      include: 'status,type,order,responsibleBy,order.object',
      searchJoin: 'and',
    }

    if (orderBy !== null) params['orderBy'] = orderBy
    if (sortedBy !== null) params['sortedBy'] = sortedBy
    if (filter.main) params['search'] = filter.main
    if (search) params['search'] = filter.main ? `${filter.main};order.title:${search}` : `order.title:${search}`

    if (isArchive) params['only-trashed'] = `1`

    return await $api
      .get('events', {
        params,
        headers: authHeader(),
      })
      .then(async r => {
        const reformatData = await r.data.data.map(el => {
          const {start_at, finish_at} = el
          return {
            ...el,
            start_at: start_at ? dayjs(start_at).unix() : null,
            finish_at: finish_at ? dayjs(finish_at).unix() : null,
          }
        })
        if (!isLoadNew) {
          await store.commit('events/getAllElements', {
            data: reformatData,
            meta: r.data.meta,
          })
        } else {
          await store.commit('events/getAllElements', {
            data: [...elements, ...reformatData],
            meta: r.data.meta,
          })
        }
        await store.commit('other/onChangeLoadingItems', false)
        return r
      })
  }

  async getFilteringEvents(search, cancelToken) {
    const {filter} = store.state.events

    const params = {
      include:
        'features,status,type,order,order.object,order.object.region,order.object.highway,createdBy,updatedBy,responsibleBy,deadlines,deadlines.human.event_position',
      searchJoin: 'and',
      search: search + (filter.main ? `;${filter.main}` : ''),
    }

    return await $api.get('events', {
      params,
      cancelToken,
      headers: authHeader(),
    })
  }

  getEvent(id) {
    const params = {
      include:
        'features,status,type,order,order.object,order.object.region,order.object.highway,createdBy,updatedBy,responsibleBy,deadlines,deadlines.human,deadlines.human.event_position',
    }
    return $api.get(`events/${id}`, {params, headers: authHeader()}).catch(e => {
      errorHandler(e)
      return e
    })
  }

  getTypes() {
    return $api.get('event-types', {headers: authHeader()}).catch(e => {
      errorHandler(e)
      return e
    })
  }

  getStatuses() {
    return $api.get('event-statuses?to=list', {headers: authHeader()}).catch(e => {
      errorHandler(e)
      return e
    })
  }

  getEventConfirmationStatuses() {
    return $api.get('event-confirmation-statuses?to=list', {headers: authHeader()}).catch(e => {
      errorHandler(e)
      return e
    })
  }

  getOrders(search) {
    let newParams = {
      include: 'object,pipeline,status,created_by,updated_by,responsible_by,deadlines',
      searchJoin: 'and',
      list: 'to',
      limit: '*',
    }

    if (search !== '') newParams['search'] = `title:${search}`

    return $api
      .get('orders', {
        params: newParams,
        headers: authHeader(),
      })
      .then(async r => {
        errorHandler(r)
        return r.data.data
      })
  }

  getPeoplesList(search = '') {
    let params = {
      include: 'organizations,priority_organization,organization_ownership',
      searchJoin: 'or',
      limit: '*',
      to: 'list',
    }
    if (search) params['search'] = `fio:${search};phone:${search}`
    return $api
      .get('humans', {
        params,
        headers: authHeader(),
      })
      .then(async r => {
        return r.data.data
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  getUsersList(search = '') {
    let params = {
      to: 'list',
    }
    if (search) params['search'] = `${search}`
    return $api
      .get('users', {
        params,
        headers: authHeader(),
      })
      .then(async r => {
        return r.data.data
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  async deleteEvents(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return await $api.delete('events', {
      params: newParams,
      headers: authHeader(),
      data: {
        ids: store.state.events.selectedItemsForDelete,
      },
    })
  }

  async archiveEvents() {
    return await $api.post(
      'restore/events',
      {
        ids: store.state.events.selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }

  createEvent(data) {
    return $api.post('events', data, {headers: authHeader()})
  }

  updateEvent(id, data) {
    return $api.patch(`events/${id}`, data, {headers: authHeader()})
  }

  createTimeSlot(data) {
    return $api.post('event-deadlines', data, {headers: authHeader()})
  }

  updateTimeSlot(id, data) {
    return $api.patch(`event-deadlines/${id}`, data, {headers: authHeader()})
  }

  deleteTimeSlot(id) {
    return $api.delete(`event-deadlines`, {
      data: {
        ids: [id],
      },
      headers: authHeader(),
    })
  }

  getPeculiarities() {
    return $api.get('event-features?to=list', {headers: authHeader()}).catch(e => {
      errorHandler(e)
      return e
    })
  }
}

export default new EventsService()
